import React from "react"

import { Link } from "gatsby"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"

const ContactPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        description={data.page.frontmatter.description}
        title={data.page.frontmatter.metaTitle}
      />
      <article className="post-content page-template">
        <div className="post-content-body">
          <h1>{data.page.frontmatter.heading}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.page.html }} />
          <hr />
          <form
            name="contact"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <p style={{ display: "none" }}>
              <label>
                Don’t fill this out if you're human: <input name="bot-field" />
              </label>
              <input type="hidden" name="form-name" value="contact" />
            </p>
            <div className="col-12">
              <label className="field__label" for="name">
                Name:
              </label>
              <input className="field__input" type="text" name="name" />
            </div>
            <div className="col-12">
              <label className="field__label" for="email">
                Email:
              </label>
              <input className="field__input" type="text" name="email" />
            </div>
            <div className="col-12">
              <label className="field__label" for="phone">
                Phone:
              </label>
              <input className="field__input" type="text" name="phone" />
            </div>
            <div className="col-12">
              <label className="field__label" for="enquiry">
                Enquiry:
              </label>
              <textarea className="field__input" name="enquiry" rows="6" />
            </div>
            <p className="col-12" style={{ textAlign: "right" }}>
              <button type="submit">Send</button>
            </p>
          </form>
        </div>
      </article>
    </Layout>
  )
}

export const CONTACT_PAGE_QUERY = graphql`
  query {
    site: site {
      siteMetadata {
        title
        author
      }
    }
    page: markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        metaTitle
        description
        heading
      }
      html
    }
  }
`

export default ContactPage
